import { Fonts, Button, Card, CardStyles } from "yuka";
import styled from "styled-components";

import OneTapPrompt from "templates/OneTapPrompt";

import DealFileReviewConnectionsTable from "./DealFileReviewConnectionsTable";
import ClientProfileKYCReviewTable from "./ClientProfileKYCReviewTable";

const PaddedWrapper = styled.div`
  padding: 0 16px;
`;

const ComplianceDashboardView = () => (
  <Card
    title="Deal Files ready for review"
    cardStyle={CardStyles.SECTIONED}
    headerTrailingContent={<OneTapPrompt displayedComponent={Button} />}
  >
    <DealFileReviewConnectionsTable />
    <PaddedWrapper>
      <Fonts.Headline2theme80>KYC needing approval</Fonts.Headline2theme80>
    </PaddedWrapper>
    <ClientProfileKYCReviewTable />
  </Card>
);

export default ComplianceDashboardView;
