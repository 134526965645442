import React from "react";
import PropTypes from "prop-types";

import { Button } from "yuka";

import AdvanceConnectionPhaseForm from "forms/AdvanceConnectionPhaseForm";

const ConnectionActions = ({ connection }) => {
  const [showForm, setShowForm] = React.useState(false);

  const approveDealFile = () => setShowForm(true);
  const closeForm = () => setShowForm(false);

  return (
    <>
      <Button onClick={approveDealFile}>Approve Deal File</Button>
      {showForm && <AdvanceConnectionPhaseForm onClose={closeForm} connection={connection} />}
    </>
  );
};

ConnectionActions.propTypes = {
  connection: PropTypes.shape({
    tradeId: PropTypes.string,
    phase: PropTypes.string,
    isDealFileLocked: PropTypes.bool,
    apiType: PropTypes.string,
  }).isRequired,
};

export default ConnectionActions;
