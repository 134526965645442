import { Table } from "yuka";

import { QUERY_KEYS, useInfiniteFetch } from "api";

import { useClientTableConfig, columns } from "ExecutionApp/ClientTableView/tableConfig";
import RelationshipsContext from "ExecutionApp/ClientTableView/relationshipContext";

const ClientTableView = () => {
  const { onRowClick, sortState, sortQueryParam, onSort } = useClientTableConfig("clients/");

  const queryParams = {
    sort: sortQueryParam,
    "page[size]": 50,
    "filter[kycStatus]": "Review",
    include: "source,zxRepresentatives",
  };

  const { isLoading, data, fetchNextPage, isFetchingNextPage, relationships } = useInfiniteFetch(
    QUERY_KEYS.CLIENT_PROFILES.list({ ...queryParams })
  );

  return (
    <RelationshipsContext.Provider value={relationships}>
      <Table
        usePercentageColumnWidths
        manualSortBy
        sortState={sortState}
        onSort={onSort}
        paginationFunc={fetchNextPage}
        isPaginationLoading={isFetchingNextPage}
        onRowClick={onRowClick}
        isLoading={isLoading}
        data={data || []}
        columns={columns}
      />
    </RelationshipsContext.Provider>
  );
};

export default ClientTableView;
