import { BrowserRouter, Route, Routes, Outlet } from "react-router-dom";
import styled from "styled-components";
import { PageSection, YukaThemeProvider, FontColors } from "yuka";

import { QueryClientProvider } from "api";
import { Unauthorized, NotFound } from "templates/ErrorPages";
import StateProvider from "utils/StateProvider";
import ClientProfileView from "ExecutionApp/ClientProfileView";
import ToastList from "templates/ToastList";

import ComplianceConnectionProfileView from "./ComplianceConnectionProfileView";
import ComplianceDashboardView from "./ComplianceDashboardView";

const Layout = styled(PageSection)`
  ${FontColors.theme80};
  flex-direction: row;
  height: 100vh;
  width: 100%;
`;

const StyledMain = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 16px;
  overflow-y: auto;
`;

const NavLayout = () => (
  <Layout>
    <StyledMain>
      <Outlet />
    </StyledMain>
  </Layout>
);

const ExecutionApp = () => (
  <YukaThemeProvider theme={{ componentVersion: 2, useTangeloTable: false }}>
    <QueryClientProvider>
      <StateProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<NavLayout />}>
              <Route index element={<ComplianceDashboardView />} />
              <Route path="connections/:id/" element={<ComplianceConnectionProfileView />} />
              <Route path="clients/:id/" element={<ClientProfileView />} />
              <Route path="401/" element={<Unauthorized />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </BrowserRouter>
        <ToastList />
      </StateProvider>
    </QueryClientProvider>
  </YukaThemeProvider>
);

export default ExecutionApp;
