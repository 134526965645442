import React, { useContext } from "react";
import PropTypes from "prop-types";

import { Definition, DefinitionListHeader, HyperLink } from "yuka";

import { getRelatedObj } from "api";
import { BUY, ROLE_BROKER, ROLE_CLIENT } from "utils/constants";
import { ZXLink } from "utils/routing";

import { RelationshipsContext } from "..";

import SourceDefinition from "./SourceDefinition";
import TicketTitle from "./TicketTitle";

/**
 * Present the ticket info (buy or sell) for a connection
 *
 * @param {object} connection
 *
 * @returns {React.Element}
 */
const TicketInfo = ({ ticket, type }) => {
  const relationships = useContext(RelationshipsContext);
  const marketOperators = ticket.marketOperators.map(marketOperator => {
    const mo = getRelatedObj(marketOperator, relationships);
    return {
      name: mo.name,
      display: (
        <span>
          <ZXLink component={HyperLink} url={mo.zxUrl} profileType="user">
            {mo.name}
          </ZXLink>
        </span>
      ),
    };
  });
  const brokers = ticket.sources
    .map(source => getRelatedObj(source, relationships))
    .filter(source => source.role === ROLE_BROKER);
  const clients = ticket.sources
    .map(source => getRelatedObj(source, relationships))
    .filter(source => source.role === ROLE_CLIENT);
  return (
    <>
      <DefinitionListHeader
        title={
          <TicketTitle title={type === BUY ? "Buyside Info" : "Sellside Info"} ticket={ticket} />
        }
      />
      <Definition label="Ticket Type" value={ticket.commitmentLevelDisplay} />
      {brokers.length ? (
        brokers.map(broker => (
          <SourceDefinition key={broker.apiId} source={broker} sourceRole="Broker" />
        ))
      ) : (
        <Definition label="Broker" />
      )}
      {clients.length ? (
        clients.map(client => (
          <SourceDefinition
            key={client.apiId}
            sourceRole={type === BUY ? "Buyer" : "Seller"}
            source={client}
          />
        ))
      ) : (
        <Definition label={type === BUY ? "Buyer" : "Seller"} />
      )}
      {marketOperators.map(marketOperator => (
        <Definition
          key={marketOperator.name}
          label="Market Operator"
          value={marketOperator.display}
        />
      ))}
    </>
  );
};

TicketInfo.propTypes = {
  ticket: PropTypes.shape({
    marketOperators: PropTypes.arrayOf(PropTypes.array),
    sources: PropTypes.arrayOf(PropTypes.array),
    commissionPercent: PropTypes.string,
    commissionPerShare: PropTypes.string,
    feeSharePercent: PropTypes.string,
    totalFee: PropTypes.string,
    commitmentLevelDisplay: PropTypes.string,
  }).isRequired,
  type: PropTypes.string.isRequired,
};

export default TicketInfo;
