import React, { useContext } from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import _ from "lodash";

import { getRelatedObj } from "api";
import { DefinitionListHeader, Definition } from "yuka";

import { RelationshipsContext } from "..";

/**
 * Present the details that have been agreed to by both parties in a match
 *
 * @param {object} props
 *
 * @returns {React.Element}
 */
const ConnectionDates = ({ connection }) => {
  const relationships = useContext(RelationshipsContext);
  const buy = getRelatedObj(connection.buy, relationships);
  const sell = getRelatedObj(connection.sell, relationships);

  const ConnectionDatesList = [
    ["Connected", connection.createdOn],
    ["Company Noticed", connection.companyNoticedOn],
    ["In Settlement On", connection.inSettlementOn],
    ["Settled", connection.settledOn],
    ["Buyer Deal File Reviewed", buy.dealFileReviewedOn],
    ["Seller Deal File Reviewed", sell.dealFileReviewedOn],
    ["Buyer Invoiced", buy.invoicedOn],
    ["Seller Invoiced", sell.invoicedOn],
    ["Buyer Fee Collection and Payment", buy.paidOn],
    ["Seller Fee Collection and Payment", sell.paidOn],
    ["Closed", connection.archivedOn],
    ["Disconnected", connection.disconnectedOn],
  ];
  return (
    <>
      <DefinitionListHeader title="Key Dates" />
      {_.map(
        ConnectionDatesList,
        ([title, date]) =>
          date && (
            <Definition
              key={title}
              label={title}
              value={DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT)}
            />
          )
      )}
    </>
  );
};

ConnectionDates.propTypes = {
  connection: PropTypes.shape({
    buy: PropTypes.arrayOf(PropTypes.string).isRequired,
    sell: PropTypes.arrayOf(PropTypes.string).isRequired,
    createdOn: PropTypes.string,
    companyNoticedOn: PropTypes.string,
    inSettlementOn: PropTypes.string,
    settledOn: PropTypes.string,
    archivedOn: PropTypes.string,
    disconnectedOn: PropTypes.string,
  }).isRequired,
};

export default ConnectionDates;
