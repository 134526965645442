import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Table } from "yuka";

import { useInfiniteFetch, QUERY_KEYS, getRelatedId } from "api";
import { PHASE_DEAL_FILE_REVIEW } from "utils/constants";
import { MixpanelSourceContext, mpTrack } from "utils/mixpanel";
import { RelationshipsContext } from "ExecutionApp/ConnectionsTableView";
import { complianceConnectionsColumns as columns } from "ExecutionApp/ConnectionsTableView/TableColumns";

import { ACTIONS, GlobalContext } from "utils/StateProvider";

const source = "connections table - compliance";
const queryParams = {
  sort: "lastEditedOn",
  "page[size]": 50,
  "filter[phase]": PHASE_DEAL_FILE_REVIEW,
};

const DealFileReviewConnectionsTable = () => {
  const { dispatch } = useContext(GlobalContext);
  const navigate = useNavigate();

  // Some click handlers that will be forwarded to the necessary cells.
  const clickTradeId = ({ value: tradeId }) =>
    navigator.clipboard
      .writeText(tradeId)
      .then(() => {
        dispatch({ type: ACTIONS.addToast, message: `Copied "${tradeId}" to clipboard.` });
      })
      .catch(() => {
        dispatch({ type: ACTIONS.addToast, message: "Failed to copy." });
      });

  const navigateToConnectionProfile = ({ source, row: { original: originalData }, event }) => {
    mpTrack("click into connection profile", {
      source,
      tradeId: originalData.tradeId,
      company: getRelatedId(originalData.company),
    });
    const isCtrlKeyHeld = event.ctrlKey || event.metaKey;
    if (isCtrlKeyHeld) {
      const url = `connections/${originalData.tradeId}/`;
      window.open(url, "_blank", "noopener,noreferrer");
    } else {
      navigate(`connections/${originalData.tradeId}/`);
    }
  };

  const { isLoading, data, fetchNextPage, isFetchingNextPage, relationships } = useInfiniteFetch(
    QUERY_KEYS.CONNECTIONS.list({ ...queryParams })
  );

  return (
    <MixpanelSourceContext.Provider value={source}>
      <RelationshipsContext.Provider value={relationships}>
        <Table
          paginationFunc={fetchNextPage}
          isPaginationLoading={isFetchingNextPage}
          isLoading={isLoading}
          data={data || []}
          columns={columns}
          source={source}
          clickTradeId={clickTradeId}
          navigateToConnectionProfile={navigateToConnectionProfile}
        />
      </RelationshipsContext.Provider>
    </MixpanelSourceContext.Provider>
  );
};

export default DealFileReviewConnectionsTable;
export { RelationshipsContext };
