import React, { createContext } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { Thumbnail, HyperLink, StyledCaption2, StyledHeadline1, LinkTypes, LinkStyles } from "yuka";

import { QUERY_KEYS, getRelatedObj, useFetch } from "api";
import { MixpanelSourceContext } from "utils/mixpanel";
import { ZXLink } from "utils/routing";

import { FrontendError, Loading } from "templates/ErrorPages";
import DealFile from "templates/DealFile";
import { useDispatch, ACTIONS } from "utils/StateProvider";

import ConnectionActions from "./ConnectionActions";
import TradeDetailsCard from "./TradeDetailsCard";

const StyledHeader = styled.div`
  display: flex;
  margin-top: 16px;
  gap: 12px;
  align-items: center;
  > :last-child {
    margin-left: auto;
  }
`;

const StyledProfileContent = styled.div`
  display: flex;
  gap: 16px;
  padding-top: 16px;
`;

const RelationshipsContext = createContext(null);

const ConnectionProfileView = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { isLoading, isLoadingError, data, relationships } = useFetch(
    QUERY_KEYS.CONNECTIONS.detail(id)
  );

  if (isLoading) {
    return <Loading />;
  }

  if (isLoadingError) {
    return <FrontendError />;
  }

  const connection = data;
  const company = getRelatedObj(connection.company, relationships);

  // Copy trade ID to clipboard
  const handleClickTradeId = () => {
    // https://developer.mozilla.org/en-US/docs/Mozilla/Add-ons/WebExtensions/Interact_with_the_clipboard
    navigator.permissions.query({ name: "clipboard-write" }).then(result => {
      if (result.state === "granted" || result.state === "prompt") {
        navigator.clipboard.writeText(connection.tradeId);
        dispatch({
          type: ACTIONS.addToast,
          message: `Copied "${connection.tradeId}" to clipboard`,
        });
      }
    });
  };

  return (
    <MixpanelSourceContext.Provider value="connection profile">
      <RelationshipsContext.Provider value={relationships}>
        <StyledCaption2>
          <HyperLink url="../" linkType={LinkTypes.LOCAL_LINK}>
            Dashboard
          </HyperLink>
          <span>
            {" "}
            / {company.name} ({connection.tradeId})
          </span>
        </StyledCaption2>
        <StyledHeader>
          <Thumbnail size="48px" src={company.thumbnail} />
          <StyledHeadline1>
            <HyperLink onClick={handleClickTradeId} linkStyle={LinkStyles.INVISIBLE}>
              {connection.tradeId}
            </HyperLink>
            &nbsp;(
            <ZXLink
              component={HyperLink}
              linkStyle={LinkStyles.INVISIBLE}
              url={company.zxUrl}
              profileType="company"
            >
              {company.legalName || company.name}
            </ZXLink>
            )
          </StyledHeadline1>
          <ConnectionActions connection={connection} />
        </StyledHeader>
        <StyledProfileContent>
          <TradeDetailsCard connection={connection} />
          <DealFile connection={connection} />
        </StyledProfileContent>
      </RelationshipsContext.Provider>
    </MixpanelSourceContext.Provider>
  );
};

export default ConnectionProfileView;
export { RelationshipsContext };
